<template>
  <div>
    <b-row align-h="center">
      <b-col cols="6">
        <p class="mb-0 h4" v-text="$t(locale.title)"/>
      </b-col>
      <b-col cols="6" class="d-flex justify-content-end">
        <div
            class="d-flex smallGap text-secondaryColor align-items-center"
            role="button"
            @click="$router.push({name: 'acountingInvoices'})"
        >
          <mdicon :width="25" :height="25" name="arrow-left-thick"/>
          <p class="mb-0" v-text="$t(locale.backTo)"/>
        </div>
      </b-col>

      <b-col cols="12" class="mt-2">
        <b-card>
          <div
              class="d-flex align-content-center justify-content-between"
          >
            <b-card-title
                class="mb-0"
                v-text="$t($route.meta.pageTitle)"
            />

            <div class="d-flex bigGap pr-2">
              <p class="mb-0" v-text="$t(locale.ocrNumber)"/>

              <p class="mb-0" v-text="$t(locale.voucherNumber)"/>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>

    <b-row>
      <b-col
          cols="12"
          lg="12"
      >
        <SecondaryCard :title="$t(locale.invoiceDetail)">
          <b-container fluid>
            <ValidationObserver ref="form">
              <b-row>
                <b-col v-if="hidden_fields.customer_number || hidden_fields.customer_name"
                       :cols="12"
                       :lg="4">
                                    <span>
                                        <b-form-group
                                            :label="$t(locale.customers)"
                                            label-for="input-1"
                                        >
                                            <v-select label="name" class="select-size-sm w-full"
                                                      v-model="singleInvoice.customer_name"
                                                      @option:selected="choosenCustomer($event)" :filterable="false"
                                                      :options="customersResult" @search="onSearch">
                                                <template slot="no-options">
                                                    Sök...
                                                </template>
                                                <template slot="option" slot-scope="option">
                                                    <div class="d-center">
                                                        {{ option.name + '(' + option.customer_number + ')' }}
                                                    </div>
                                                </template>
                                                <template slot="selected-option" slot-scope="option">
                                                    <div class="selected d-center">
                                                        {{ option.name }}
                                                    </div>
                                                </template>
                                            </v-select>
                                        </b-form-group>
                                    </span>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.invoice_date">
                  <b-form-group
                      :label="$t(locale.invoiceDate)"
                      label-for="input-1"
                  >
                    <date-picker
                        v-model="singleInvoice.invoice_date"
                        valueType="format"
                        class="w-100"
                        prefix-class="xmx"
                        :placeholder="$t(locale.invoiceDate)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.due_date">
                  <b-form-group
                      :label="$t(locale.dueDate)"
                      label-for="input-1"
                  >
                    <date-picker
                        v-model="singleInvoice.due_date"
                        valueType="format"
                        class="w-100"
                        prefix-class="xmx"
                        :placeholder="$t(locale.dueDate)"
                    />
                  </b-form-group>
                </b-col>


                <b-col cols="12" lg="2" v-if="hidden_fields.invoice_type">
                  <b-form-group
                      :label="$t(locale.invoiceType)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                                                    {
                                                        label: 'invoicesCard.invoice',
                                                        active: true,
                                                        value: 'INVOICE',
                                                    },
                                                    {
                                                        label: 'invoicesCard.cash',
                                                        active: false,
                                                        value: 'CASH',
                                                    },
                                                ]"
                        :defaultValue="true"
                        v-model="singleInvoice.invoice_type"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>
                <b-col cols="12" lg="2" v-if="hidden_fields.credit">
                  <b-form-group
                      :label="$t(locale.creditInvoice)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                                                    {
                                                        label: 'CustomersCardEdit.yes',
                                                        active: true,
                                                        value: true,
                                                    },
                                                    {
                                                        label: 'CustomersCardEdit.no',
                                                        active: false,
                                                        value: false,
                                                    },
                                                ]"
                        :defaultValue="true"
                        v-model="singleInvoice.credit"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />

                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.terms_of_payment">
                  <b-form-group
                      :label="$t(locale.paymentTerm)"
                      label-for="input-1"
                  >

                    <v-select
                        v-model="singleInvoice.terms_of_payment"
                        :placeholder="$t(locale.paymentTerm)"
                        label="value"
                        :options="termsOfPayment"
                        @option:selected="computeDueDate"
                        class="resource-selector select-size-sm w-full d-inline-block"
                    />
                  </b-form-group>

                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.our_reference">
                  <b-form-group
                      :label="$t(locale.ourReference)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.our_reference"
                        :placeholder="$t(locale.ourReference)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2">
                  <b-form-group
                      :label="$t(locale.yourDate)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        :placeholder="$t(locale.yourDate)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.your_order_number">
                  <b-form-group
                      :label="$t(locale.yourOrderNumber)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.your_order_number"
                        :placeholder="$t(locale.yourOrderNumber)"
                    />
                  </b-form-group>
                </b-col>


                <b-col cols="12" lg="2" v-if="hidden_fields.terms_of_delivery">
                  <b-form-group
                      :label="$t(locale.deliveryTerm)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.terms_of_delivery"
                        :placeholder="$t(locale.deliveryTerm)"
                        :options="deliveryTerms"
                        @option:selected="computeSelect($event, 'terms_of_delivery')"
                        class="resource-selector select-size-sm w-full d-inline-block whitespace-nowrap"
                    />
                  </b-form-group>

                </b-col>


                <b-col cols="12" lg="2" v-if="hidden_fields.way_of_delivery">
                  <b-form-group
                      :label="$t(locale.deliveryMethod)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.way_of_delivery"
                        :placeholder="$t(locale.deliveryMethod)"
                        :options="waysOfDelivery"
                        @option:selected="computeSelect($event, 'way_of_delivery')"
                        class="resource-selector select-size-sm w-full d-inline-block"
                    />
                  </b-form-group>

                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.currency">
                  <b-form-group
                      :label="$t(locale.currency)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.currency"
                        :placeholder="$t(locale.currency)"
                        @option:selected="computeSelect($event, 'currency')"
                        :options="currencies"
                        class="resource-selector select-size-sm w-full d-inline-block"
                    />
                  </b-form-group>

                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.your_reference">
                  <b-form-group
                      :label="$t(locale.yourRef)"
                      label-for="input-1"
                  >
                    <b-form-input
                        size="sm"
                        v-model="singleInvoice.your_reference"
                        :placeholder="$t(locale.yourRef)"
                    />
                  </b-form-group>
                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.price_list">
                  <b-form-group
                      :label="$t(locale.priceList)"
                      label-for="input-1"
                  >
                    <v-select
                        v-model="singleInvoice.price_list"
                        :placeholder="$t(locale.priceList)"
                        @option:selected="computeSelect($event, 'price_list')"
                        :options="priceLists"
                        class="resource-selector select-size-sm w-full d-inline-block"
                    />
                  </b-form-group>

                </b-col>

                <b-col cols="12" lg="2" v-if="hidden_fields.vat_included">
                  <b-form-group
                      :label="$t(locale.priceInvat)"
                      label-for="input-1"
                  >
                    <LittleTabs
                        :tabs="[
                                                    {
                                                        label: 'CustomersCardEdit.yes',
                                                        active: true,
                                                        value: true,
                                                    },
                                                    {
                                                        label: 'CustomersCardEdit.no',
                                                        active: false,
                                                        value: false,
                                                    },
                                                ]"
                        :defaultValue="true"
                        v-model="singleInvoice.vat_included"
                        buttonSize="sm"
                        containerStyle="justify-content-start"
                        btnClass="tabWrapperSmall2"
                    />
                  </b-form-group>
                </b-col>

              </b-row>
            </ValidationObserver>
          </b-container>
        </SecondaryCard>
      </b-col>


      <b-col
          cols="12"
          lg="6"
      >
        <SecondaryCard class="mt-2" :title="$t(locale.customerDetails)">
          <b-container fluid>
            <b-row>
              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.name)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.customer_name"
                      :placeholder="$t(locale.name)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.organizationName)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.organisation_number"
                      :placeholder="$t(locale.organizationName)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceAddress)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.address1"
                      :placeholder="$t(locale.invoiceAddress)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.phone)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.phone1"
                      :placeholder="$t(locale.phone)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceAddress2)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.address2"
                      :placeholder="$t(locale.invoiceAddress2)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.email)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.email_information ? singleInvoice.email_information.email_address_to : ''"
                      :placeholder="$t(locale.email)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.zipCode1)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.zip_code"
                          :placeholder="$t(locale.zipCode1)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.city1)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.city"
                          :placeholder="$t(locale.city1)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.zip2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.zip2)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6">
                    <b-form-group
                        :label="$t(locale.city2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.city2)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <b-col
          cols="12"
          lg="6"
      >
        <SecondaryCard class="mt-2" :title="$t(locale.deliveryDetails)">
          <b-container fluid>
            <b-row>
              <b-col cols="12" lg="6" v-if="hidden_fields.delivery_name">
                <b-form-group
                    :label="$t(locale.deliveryCustomerName)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_name"
                      :placeholder="$t(locale.deliveryCustomerName)"
                  />
                </b-form-group>
              </b-col>

              <!-- <b-col cols="12" lg="6">
                  <b-form-group
                          :label="$t(locale.delivOrgName)"
                          label-for="input-1"
                      >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.customer_name"
                          :placeholder="$t(locale.delivOrgName)"
                      />
                  </b-form-group>
              </b-col> -->

              <b-col cols="12" lg="6" v-if="hidden_fields.delivery_address1">
                <b-form-group
                    :label="$t(locale.deliAddress1)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_address1"
                      :placeholder="$t(locale.deliAddress1)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6" v-if="hidden_fields.phone2">
                <b-form-group
                    :label="$t(locale.deliPhone)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.phone2"
                      :placeholder="$t(locale.deliPhone)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6" v-if="hidden_fields.delivery_address2">
                <b-form-group
                    :label="$t(locale.deliAddress2)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.delivery_address2"
                      :placeholder="$t(locale.deliAddress2)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6" v-if="hidden_fields.email_address_from">
                <b-form-group
                    :label="$t(locale.delivEmail)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :value="singleInvoice.email_information ? singleInvoice.email_information.email_address_from : ''"
                      :placeholder="$t(locale.delivEmail)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6" v-if="hidden_fields.delivery_zip_code">
                    <b-form-group
                        :label="$t(locale.delivZip)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.delivery_zip_code"
                          :placeholder="$t(locale.delivZip)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.delivery_city">
                    <b-form-group
                        :label="$t(locale.delivCity)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          :value="singleInvoice.delivery_city"
                          :placeholder="$t(locale.delivCity)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
              <b-col cols="12" lg="6">
                <b-row>
                  <b-col cols="12" lg="6" v-if="hidden_fields.delivery_zip_code">
                    <b-form-group
                        :label="$t(locale.delivZip2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          v-model="singleInvoice.delivery_zip_code"
                          :placeholder="$t(locale.delivZip2)"
                      />
                    </b-form-group>
                  </b-col>
                  <b-col cols="12" lg="6" v-if="hidden_fields.delivery_city">
                    <b-form-group
                        :label="$t(locale.delivCity2)"
                        label-for="input-1"
                    >
                      <b-form-input
                          size="sm"
                          v-model="singleInvoice.delivery_city"
                          :placeholder="$t(locale.delivCity2)"
                      />
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <!-- InvoiceRow -->
      <invoiceRowComponentVue
          :invoice_rows="singleInvoice.invoice_rows"
          :invoice_country="currentCustomer.country_code"
          @removeRow="removeRow"
          :key="singleInvoice.invoice_rows.length"
          @calculateRow="reCalculateValues"
          :booked="singleInvoice.booked"
          :cancelled="singleInvoice.cancelled"
          :vat_included="singleInvoice.vat_included"
          :fully_paid="singleInvoice.fully_paid"
          :submitForm="submitForm"
          :locale="locale"

      />
      <!-- @updateContract="createSingleInvoice" -->

      <b-col
          cols="12"
          lg="8"
      >
        <SecondaryCard class="mt-2" :title="$t(locale.otherDetails)">
          <b-container fluid>
            <b-row>
              <!-- <b-col cols="12" lg="6">
                  <b-form-group
                          :label="$t(locale.shippingCharge)"
                          label-for="input-1"
                      >
                      <b-form-input
                          size="sm"
                          :placeholder="$t(locale.shippingCharge)"
                      />
                  </b-form-group>
              </b-col> -->

              <b-col cols="12" lg="6" v-if="hidden_fields.freight">
                <b-form-group
                    :label="$t(locale.invoiceCharge)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      v-model="singleInvoice.freight"
                      :placeholder="$t(locale.invoiceCharge)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.invoiceDiscount)"
                    label-for="input-1"
                >
                  <b-form-input
                      size="sm"
                      :placeholder="$t(locale.invoiceDiscount)"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.print)"
                    label-for="input-1"
                >
                  <v-select
                      v-model="singleInvoice.print_template"
                      :placeholder="$t(locale.print)"
                      :options="printTemplates"
                      @option:selected="computeSelect($event, 'print_template')"
                      class="resource-selector select-size-sm w-full d-inline-block"
                  />
                </b-form-group>

              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group
                    :label="$t(locale.language)"
                    label-for="input-1"
                >
                  <v-select
                      v-model="singleInvoice.language"
                      :placeholder="$t(locale.language)"
                      @option:selected="computeSelect($event, 'language')"
                      :options="[]"
                      class="resource-selector select-size-sm w-full d-inline-block"
                  />
                </b-form-group>

              </b-col>

              <b-col cols="12" lg="6">
                <b-form-group :label="'Remarks'">
                  <b-form-textarea
                      size="lg"
                      v-model="singleInvoice.remarks"
                      :placeholder="'Remarks'"
                  />
                </b-form-group>
              </b-col>

            </b-row>
          </b-container>
        </SecondaryCard>
      </b-col>

      <b-col cols="12" lg="4">
        <b-card class="mt-2">
          <b-card-text class="d-flex flex-column smallGap">
            <div
                v-for="(faktur, index) in fakture"
                :key="index"
                class="d-flex align-items-center"
            >
              <p class="mb-0" v-text="$t(faktur.label)"/>
              <div class="border-between"/>
              <p class="mb-0" v-text="faktur.value"/>
            </div>

            <div class="sds devider my-2"/>

            <div
                class="d-flex justify-content-between align-items-center"
            >
              <p
                  class="mb-0 font-weight-bold"
                  v-text="$t(locale.total)"
              />

              <p class="mb-0">{{ singleInvoice.total_to_pay }}</p>
            </div>
          </b-card-text>
        </b-card>
      </b-col>

      <b-col cols="12" class="mt-2">
        <b-row align-v="center">
          <b-col cols="12" lg="6">
            <div class="d-flex bigGap align-items-center">

            </div>
          </b-col>
          <b-col
              cols="12"
              lg="6"
              class="d-flex smallGap justify-content-end"
          >
            <b-button
                v-for="(btn, index) in buttonList"
                :key="index"
                :variant="btn.variant"
                :disabled="btn.processing"
                @click="btn.click(btn)"

            >
              <!-- @click="btn.submit ? updateContract() : $router.go(-1)" -->
              <span>
                                <span v-if="btn.submit">
                                    <span v-if="btn.processing">{{ $t('Message.loading') }}</span>
                                    <span v-else>{{ $t(btn.label) }}</span>
                                </span>
                                <span v-else>{{ $t(btn.label) }}</span>
                            </span>
            </b-button>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import DatePicker from 'vue2-datepicker';
import vSelect from 'vue-select';
import debounce from 'lodash.debounce'
import {registersMixin} from '@/mixins/registersMixin';
import {dateMixins} from '@/mixins/dateMixins';
import moment from "moment"
import invoiceRowComponentVue from './invoiceRowComponent.vue';
import useAppConfig from '@core/app-config/useAppConfig';
import {invoiceCreate} from './constants/invoice'
import bus from '@/bus';
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";
import PAGES from "@/services/helpers/constant";

export default {
  name: 'createInvoices',
  mixins: [registersMixin, dateMixins],
  components: {
    vSelect,
    DatePicker,
    ValidationProvider,
    ValidationObserver,
    invoiceRowComponentVue, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      loading: true,
      hidden_fields: {...JSON.parse(JSON.stringify(PAGES['accounting_data']['invoices']))},
      singleInvoice: {...invoiceCreate},
      customersResult: [],
      selectedCustomer: {},
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      projects: [],
      costCenter: [],
      updating: false,
      currentCustomer: {},
      previousVatIncluded: false,
      submitForm: false,

      fakture: [
        {
          label: 'invoicesEditCard.net',
          value: 0,
          fieldValue: 'net'
        },
        {
          label: 'invoicesEditCard.gross',
          value: 0,
          fieldValue: 'gross'
        },
        {
          label: 'invoicesCard.table.vat',
          value: 0,
          fieldValue: 'total_vat'
        },
        {
          label: 'invoicesEditCard.totalExVat',
          value: 0,
          fieldValue: 'total'
        },
      ],

      buttonList: [
        {
          label: 'UsersCard.cancel',
          value: 'cancel',
          variant: 'outline-secondary',
          click: this.cancelData,
          processing: false,
          submit: false,
        },
        {
          label: 'invoicesEditCard.saveInvoice',
          value: 'save',
          variant: 'primary',
          click: this.saveInvoice,
          processing: false,
          submit: true,
        },
        // {
        //     label: 'invoicesEditCard.bookkeepInvoice',
        //     value: 'bookkeep',
        //     variant: 'warning',
        //     click : this.runbookkeepInvoice,
        //     processing : false,
        //     submit : true,
        // },
      ],
      accountResults: [],

      locale: {
        ocrNumber: 'invoicesEditCard.ocrNumber',

        invoiceDetail: 'invoicesEditCard.invoiceDetails',
        customerDetails: 'CustomersCardEdit.customerDetail',
        deliveryDetails: 'invoicesEditCard.deliveryDetails',
        invoiceRow: 'invoicesEditCard.invoiceRows',
        otherDetails: 'invoicesEditCard.othersDetails',
        customers: 'StatisticsCard.tabs.customers',
        invoiceDate: 'invoicesEditCard.invoiceDate',
        dueDate: 'dashboardCard.table.dueDateHead',
        invoiceType: 'invoicesCard.invoiceType',
        creditInvoice: 'invoicesEditCard.creditInvoice',
        paymentTerm: 'InvoiceCardCustomerEdit.paymentTerms',
        ourReference: 'InvoiceCardCustomerEdit.ourReference',
        yourDate: 'invoicesEditCard.yourDate',
        yourOrderNumber: 'invoicesEditCard.yourOrderNumber',
        deliveryTerm: 'InvoiceCardCustomerEdit.deliveryTerms',
        deliveryMethod: 'InvoiceCardCustomerEdit.deliveryMethod',
        currency: 'InvoiceCardCustomerEdit.currency',
        yourRef: 'InvoiceCardCustomerEdit.yourReference',
        priceList: 'invoicesEditCard.priceList',
        priceInvat: 'invoicesEditCard.priceIncVat',
        name: 'Message.tableHeader.name',
        organizationName: 'invoicesEditCard.organisationNumber',
        invoiceAddress: 'invoicesEditCard.invoiceAddress1',
        phone: 'CustomersCard.table.phone',
        invoiceAddress2: 'invoicesEditCard.invoiceAddress1',
        email: 'Message.tableHeader.email',
        zipCode1: 'BillingPageCard.leftSide.Form.zipCode',
        city1: 'BillingPageCard.leftSide.Form.city',
        zip2: 'BillingPageCard.leftSide.Form.zipCode',
        city2: 'BillingPageCard.leftSide.Form.city',
        deliveryCustomerName: 'Message.tableHeader.name',
        delivOrgName: 'invoicesEditCard.organisationNumber',
        deliAddress1: 'invoicesEditCard.invoiceAddress1',
        deliPhone: 'CustomersCard.table.phone',
        deliAddress2: 'invoicesEditCard.invoiceAddress1',
        delivEmail: 'Message.tableHeader.email',
        delivZip: 'BillingPageCard.leftSide.Form.zipCode',
        delivCity: 'BillingPageCard.leftSide.Form.city',
        delivZip2: 'BillingPageCard.leftSide.Form.zipCode',
        delivCity2: 'BillingPageCard.leftSide.Form.city',
        shippingCharge: 'InvoiceCardCustomerEdit.shippingCharge',
        invoiceCharge: 'InvoiceCardCustomerEdit.invoiceCharge',
        invoiceDiscount: 'InvoiceCardCustomerEdit.invoiceDiscount',
        print: 'invoicesEditCard.printoutTemplate',
        language: 'invoicesEditCard.language',
        InvoiceTxt: 'InvoiceCardCustomerEdit.invoiceText',

        send: 'Contact.contactInformation.form.button',
        title: 'invoicesCard.createInvoices',
        voucherNumber: 'invoicesEditCard.voucherNumbers',
        sendInvoice: 'invoicesEditCard.sendInvoice',
        backTo: 'golfRelatedCard.detail.backOverview',
        total: 'invoicesCard.table.total',
      },
    };
  },
  watch: {
    "singleInvoice.vat_included": function (newVal, oldVal) {
      this.reCalculateValues(null);
    },
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message';
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
    const invoiceStorage = window.localStorage.getItem('__invoiceD__')
    if (invoiceStorage) {
      const parseData = JSON.parse(invoiceStorage)
      this.singleInvoice = parseData
      // remove data
      window.localStorage.removeItem('__invoiceD__')
    }
    this.disabledFields()
    this.getAllRegisters()
    this.fetchCustomers()
  },
  methods: {
    disabledFields() {
      let hidden_object = this.hiddenResource
      try {
        // this.$useJwt.hiddenResource({method: 'get', URL: `/${hidden_object['id']}/`}, {}).then()
        hidden_object['hidden_fields']['accounting_data']['contracts'].map(item => {
          this.hidden_fields[item] = false
        })
      } catch (e) {

      }
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    async getAllRegisters() {
      const waysOfDelivery = await this.$useJwt.getAllRegisters('wayofdelivery')
      const deliveryTerms = await this.$useJwt.getAllRegisters('termsofdelivery')
      const termsOfPayment = await this.$useJwt.getAllRegisters('termsofpayment')
      const printTemplates = await this.$useJwt.getAllRegisters('printtemplates?type=invoice')
      const priceLists = await this.$useJwt.getAllRegisters('pricelists')
      const currencies = await this.$useJwt.getAllRegisters('currencies')
      const costcenter = await this.$useJwt.getAllRegisters('costcenters')
      const projects = await this.$useJwt.getAllRegisters('projects')

      this.waysOfDelivery = waysOfDelivery.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.deliveryTerms = deliveryTerms.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.termsOfPayment = termsOfPayment.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.printTemplates = printTemplates.data.results.map(el => {
        return {label: el.name, value: el.template, id: el.uuid}
      })
      this.priceLists = priceLists.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.currencies = currencies.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.costCenter = costcenter.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
      this.projects = projects.data.results.map(el => {
        return {label: el.description, value: el.code}
      })
    },
    // getSingleInvoice (uuid) {
    //     this.loading = true
    //     this.$useJwt.getInvoices({ URL : `/${uuid}`, method : 'get'}).then(res => {
    //         this.singleInvoice = res.data.data
    //         this.previousVatIncluded = this.singleInvoice.vat_included;
    //         // this.getCurrentCustomer(this.singleInvoice.customer_number);

    //         this.loading = false

    //         this.fakture.map(el => {
    //             if (this.singleInvoice[el.fieldValue]) {
    //                 el.value = this.singleInvoice[el.fieldValue]
    //             }
    //         })
    //     })
    //     .catch(err => this.loading = false)
    // },
    getCurrentCustomer(id) {
      this.$useJwt.getCustomersByid(`${id}/`).then(res => {
        this.currentCustomer = res.data.data
      })
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.search(loading, search, this);
      }
    },
    search: debounce(function (loading, search, vm) {
      this.$useJwt.searchCustomers(`?search=${escape(search)}`)
          .then(res => {
            this.customersResult = res.data.data
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    fetchCustomers: function () {
      this.$useJwt.searchCustomers(``)
          .then(res => {
            this.customersResult = res.data.data
          })
          .catch(err => {
          })
    },
    choosenCustomer(customer) {
      this.currentCustomer = customer
      if (!customer.active) {
        this.popupMsg(
            this.$t('Message.Failed'),
            'This customer is not active. Please select another customer.',
            'AlertTriangleIcon',
            'danger'
        );
        form.value = ''
        return;
      }
      this.singleInvoice.customer_number = customer.customer_number;
      this.singleInvoice.customer_name = customer.name;
      this.singleInvoice.address1 = customer.address1;
      this.singleInvoice.address2 = customer.address2;
      this.singleInvoice.zip_code = customer.zip_code;
      this.singleInvoice.city = customer.city;
      this.singleInvoice.organisation_number = customer.organisation_number;
      this.singleInvoice.phone1 = customer.phone1;
      this.singleInvoice.city = customer.city;
      this.singleInvoice.country = customer.country;
      this.singleInvoice.delivery_name = customer.delivery_name;
      this.singleInvoice.delivery_address1 = customer.delivery_address1;
      this.singleInvoice.delivery_address2 = customer.delivery_address2;
      this.singleInvoice.delivery_zip_code = customer.delivery_zip_code;
      this.singleInvoice.delivery_city = customer.delivery_city;
      this.singleInvoice.delivery_date = customer.delivery_date;
      this.singleInvoice.phone2 = customer.phone2;
      this.singleInvoice.email_address_to = customer.email_address_to;
      this.singleInvoice.delivery_city = customer.delivery_city;
      this.singleInvoice.delivery_country = customer.delivery_country;
    },
    removeRow(item) {
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }
      // if (this.singleInvoice.booked === true) {
      //     this.popupMsg(
      //         this.$t('Message.Failed'),
      //         'Invoice already bookkept , invoice row can not be removed',
      //         'AlertTriangleIcon',
      //         'danger'
      //     );
      //     return
      // }
      // const filter = this.singleInvoice.invoice_rows.filter(el => el.row_id !== item.row_id)
      // this.singleInvoice.invoice_rows = filter
      this.singleInvoice.invoice_rows.splice(item, 1);
      this.reCalculateValues(null);
    },
    computeDueDate(item) {
      this.singleInvoice.terms_of_payment = item.value
      if (!isNaN(parseInt(this.singleInvoice.terms_of_payment))) {
        this.singleInvoice.due_date = moment(this.singleInvoice.invoice_date)
            .add(parseInt(this.singleInvoice.terms_of_payment), "days")
            .format("YYYY-MM-DD");
      } else {
        this.singleInvoice.due_date = this.singleInvoice.invoice_date;
      }
    },
    computeSelect(item, el) {
      this.singleInvoice[el] = item.value
    },
    switchLittleTabs(index, tabs, type) {
      tabs.find(item => item.active == true).active = false;


      this.$nextTick(() => {
        this.singleInvoice[type] = tabs[index].value;

        tabs[index].active = true;
      })
    },
    reCalculateValues(item = null) {
      let total = 0;
      let total_excluding_vat = 0;
      let total_vat = 0;

      this.singleInvoice.invoice_rows.map((element) => {
        let vat_amount = 0;

        total_excluding_vat += parseFloat(element.total_excluding_vat);

        vat_amount =
            element.total_excluding_vat * (1 + element.vat / 100) -
            element.total_excluding_vat;
        total += parseFloat(
            element.total_excluding_vat * (1 + element.vat / 100)
        );
        total_vat += vat_amount;

        if (this.singleInvoice.vat_included) {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2) *
              (
                  1 -
                  parseFloat(
                      this.removePercentage(element.discount) / 100
                  )
              ).toFixed(2)
          ).toFixed(2);
          element.price = (
              parseFloat(element.price_excluding_vat).toFixed(2) *
              parseFloat(1 + parseFloat(element.vat) / 100).toFixed(2)
          ).toFixed(2);
        } else {
          element.total = (
              parseInt(element.delivered_quantity) *
              parseFloat(element.price_excluding_vat).toFixed(2) *
              (
                  1 -
                  parseFloat(
                      this.removePercentage(element.discount) / 100
                  )
              ).toFixed(2)
          ).toFixed(2);

          element.total_excluding_vat = parseFloat(
              element.total_excluding_vat
          ).toFixed(2);

          element.price_excluding_vat = parseFloat(
              element.price_excluding_vat
          ).toFixed(2);

          element.price = parseFloat(element.price_excluding_vat).toFixed(2);
        }
      });

      if (item !== null) {
        if (item.delivered_quantity && item.price_per_unit) {
          if (this.singleInvoice.vat_included) {
            item.total = (
                parseInt(item.delivered_quantity) *
                parseFloat(item.price_per_unit) *
                (1 -
                    parseFloat(
                        this.removePercentage(item.discount) / 100
                    ).toFixed(2))
            ).toFixed(2);
            item.total_excluding_vat = (
                item.total /
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          } else {
            item.total_excluding_vat = (
                parseInt(item.delivered_quantity) *
                parseFloat(
                    item.price_per_unit *
                    parseFloat(
                        1 -
                        parseFloat(
                            this.removePercentage(item.discount) /
                            100
                        ).toFixed(2)
                    ).toFixed(2)
                )
            ).toFixed(2);
            item.total = (
                item.total_excluding_vat *
                (1 + parseFloat(item.vat) / 100)
            ).toFixed(2);
          }

          let vat = 0;
          // total += parseFloat(item.total);
          total_excluding_vat += parseFloat(item.total_excluding_vat).toFixed(
              2
          );

          vat = (item.total - item.total_excluding_vat).toFixed(2);
          total_vat += vat;
        }
      }

      this.singleInvoice.gross = parseFloat(total).toFixed(2);
      this.singleInvoice.total_vat = parseFloat(total_vat).toFixed(2);
      this.singleInvoice.total = parseFloat(total_excluding_vat).toFixed(2);
      this.singleInvoice.total_to_pay = parseFloat(total).toFixed(2);
      this.fakture[0].value = this.singleInvoice.total
      this.fakture[1].value = this.singleInvoice.gross
      this.fakture[2].value = this.singleInvoice.total_vat
      this.fakture[3].value = this.singleInvoice.total
    },
    createSingleInvoice(buttonItem = null) {
      this.submitForm = false;
      // if (this.CURRENT_USER.access === "Read") {
      //     return this.$toast.info(this.$t("limited_read_access"));
      // }

      if (
          this.singleInvoice.customer_number === "" ||
          this.singleInvoice.customer_name === ""
      ) {
        this.popupMsg(
            this.$t('Message.Failed'),
            'Customer information required',
            'AlertTriangleIcon',
            'danger'
        );
        return
      } else {
        buttonItem.processing = buttonItem ? true : false
        this.processing = true;
        let data_to_save = {};


        let exclude = [
          "email_information",
          "edi_information",
          "invoice_rows",
          "labels",
          "due_date",
          "invoice_date",
          "not_completed",
          "delivery_date",
          "failed_to_pause",
          "file",
          "fully_paid",
        ];

        let singleInvoice = this.singleInvoice;
        let email_information = {};
        let edi_information = {};
        let invoice_row = this.singleInvoice.invoice_rows;
        let new_invoice_rows = [];
        for (const [key, value] of Object.entries(singleInvoice)) {
          if (!exclude.includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = value;
            }
          }
          if (["due_date", "invoice_date", "delivery_date"].includes(key)) {
            if (value !== "" && value !== null && value !== undefined) {
              data_to_save[key] = moment(value).format("YYYY-MM-DD");
            }
          }
        }
        for (const [key, value] of Object.entries(
            singleInvoice.email_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            email_information[key] = value;
          }
        }
        for (const [key, value] of Object.entries(
            singleInvoice.edi_information
        )) {
          if (value !== null && value !== "" && value !== undefined) {
            edi_information[key] = value;
          }
        }
        if (Object.keys(email_information).length > 0) {
          data_to_save["email_information"] = email_information;
        }
        if (Object.keys(edi_information).length > 0) {
          data_to_save["edi_information"] = edi_information;
        }
        invoice_row.map((item) => {
          for (const [key, value] of Object.entries(item)) {
            if (value === "" || value === null) {
              delete item[key];
            }
          }
          new_invoice_rows.push(JSON.parse(JSON.stringify(item)));
        });

        data_to_save.invoice_rows = new_invoice_rows;

        const payload = data_to_save
        const mode = {URL: '', method: 'post'}

        this.$useJwt.getInvoices(mode, payload)
            .then(res => {
              buttonItem.processing = buttonItem ? false : false
              this.singleInvoice = {...invoiceCreate}

              this.fakture.map(el => {
                el.value = 0
              })

              bus.$emit('clear-row', {})

              this.popupMsg(
                  this.$t('Message.Success'),
                  res.data[this.message],
                  'CheckIcon',
                  'success'
              );

            })
            .catch(err => {
              buttonItem.processing = buttonItem ? false : false
              this.sending = false
              this.popupMsg(
                  this.$t('Message.Failed'),
                  err.response.data[this.message],
                  'AlertTriangleIcon',
                  'danger'
              );
              if (err.response.status === 403) {
                window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
              }
            })
      }
    },
    searchAccounts(search, loading) {
      if (search.length) {
        loading(true);
        this.searchRemoteAccounts(loading, search, this);
      }
    },
    searchRemoteAccounts: debounce(function (loading, search, vm) {
      this.$useJwt.getAllRegisters(`accounts?search=${escape(search)}`)
          .then(res => {
            this.accountResults = res.data.results
            loading(false);
          })
          .catch(err => {
          })
    }, 350),
    changeAccounts(account) {
      this.registerPaymentForm[2].value = account.number
    },
    cancelData() {
      this.$router.go(-1)
    },
    saveInvoice(item) {
      this.createSingleInvoice(item)
    },
    runbookkeepInvoice(item) {
    },
  }
};
</script>

<style scoped lang="scss">
.resource-selector select-size-sm w-full {
  width: 100%;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/datepicker.scss';
</style>

<style scoped>
.border-between {
  display: block;
  border-top: 1px dashed #cbd4e1;
  flex-grow: 1;
  margin: 0 10px;

  margin-top: 2px;
}

.devider {
  border: 1px solid #64748b;
}
</style>
